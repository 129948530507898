import * as React from 'react';


type Props = React.HTMLAttributes<HTMLButtonElement>;

export default function ViewButton({ children, ...other }: Props) {
  return (
    <button
      {...other}
      type="button"
      aria-label="View product details"
    >
      {children}
      <span>View</span>
    </button>
  );
}
