import * as React from 'react';
import clsx from 'clsx';


type Props = React.HTMLAttributes<HTMLDivElement> & {
  value: string;
  tagName?: string;
}

export default function HtmlContent({ value, tagName = 'div', className, ...other }: Props) {
  const props = {
    ...other,
    className: clsx('format', className),
    dangerouslySetInnerHTML: {
      __html:
      value,
    },
  };

  return React.createElement(tagName, props);
}
