import * as React from 'react';
import OverlayTrigger from 'react-overlay-trigger';
import clsx from 'clsx';
import { useRoutes, useEventListener } from '@core/hooks';
import Link from '@components/Link';
import InfoIcon from '@assets/info-small.svg';
import * as styles from './License.module.scss';


type TooltipProps = React.HTMLAttributes<HTMLDivElement> & {
  onClose?: () => void;
};

const Tooltip = React.forwardRef<HTMLDivElement, TooltipProps>((
  { onClose, ...other },
  ref,
) => {
  const { route, EULA } = useRoutes();

  useEventListener('resize', onClose!);

  return (
    <div
      {...other}
      className="tooltip"
      role="tooltip"
      ref={ref}
    >
      <h4>Royalty free</h4>
      <p>With respect to any number of commercial projects, with no limit on sales or views.</p>
      <Link to={route(EULA)} external>Read EULA</Link>
    </div>
  );
});

type ButtonProps = {
  tooltipId: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((
  {
    tooltipId,
    ...other
  },
  ref,
) => {
  return (
    <button
      {...other}
      className={styles.button}
      type="button"
      aria-describedby={tooltipId}
      aria-label="Show license description"
      ref={ref}
    >
      <InfoIcon className={styles.icon}/>
    </button>
  );
});

export default function License({ className, ...other }: React.HTMLAttributes<HTMLDivElement>) {
  const componentId = React.useId();
  return (
    <div {...other} className={clsx(styles.container, className)}>
      <span className={styles.text}>Extended Commercial License</span>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id={componentId}/>}
        triggers={['click']}
      >
        <Button tooltipId={componentId}/>
      </OverlayTrigger>
    </div>
  );
}
