import * as React from 'react';
import Spinner from '@components/Spinner';


type Props = React.HTMLAttributes<HTMLButtonElement> & {
  isLoading?: boolean;
  isInCart?: boolean;
  isInBundle?: boolean;
};

export default function AddToCartButton({ isLoading = false, isInCart = false, isInBundle = false, ...other }: Props) {
  return (
    <button
      {...other}
      type="button"
      disabled={isLoading || isInCart || isInBundle}
    >
      {isLoading ?
        <Spinner size="small"/> :
        isInBundle ? 'In Bundle'
          : isInCart ?
            'In Cart' :
            'Add to Cart'
      }
    </button>
  );
}
