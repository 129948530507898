import * as React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useCart } from '@core/hooks';
import { LABEL, CART, POSITION } from '@core/constants';
import { calcPrice, pluralize } from '@core/utils';
import BaseHead from '@components/Head';
import Main from '@components/Main';
import Centerer from '@components/Centerer';
import Content from '@components/Content';
import Sidebar from '@components/Sidebar';
import ArrayList from '@components/ArrayList';
import Price from '@components/Price';
import Title from '@components/Title';
import HtmlContent from '@components/HtmlContent';
import ProductRow from '@components/store/ProductRow';
import License from '@components/store/License';
import Excerpt from '@components/store/Excerpt';
import CartControls from '@components/store/CartControls';
import { SiteData, PostgresData, Bundle, Product } from '@interface/gatsby';
import { PageProps } from '@interface/common';
import * as styles from './bundle.module.scss';


type DataType = SiteData & PostgresData<Record<'bundle', Bundle>>;

export default function BundleTemplate({ data, isAuthenticating, isFetchingCart }: PageProps<DataType>) {
  const { bundle } = data.postgres;
  const { addBundleToCart, removeFromCart, isInCart } = useCart();

  const image = getImage(bundle.cover);
  const quantity = bundle.items.length;
  const subtotal = bundle.price;
  const total = calcPrice(subtotal, bundle.discount);
  const savedAmount = subtotal - total;

  const inCart = React.useMemo(() => isInCart(bundle.id, CART.ITEM.BUNDLES), [bundle.id, isInCart]);

  const products = React.useMemo(() => {
    return bundle.items.sort((a, b) => {
      return Date.parse(b.product.createdAt!) - Date.parse(a.product.createdAt!);
    });
  }, [bundle.items]);

  const handleAddToCartClick = React.useCallback(() => {
    return addBundleToCart(bundle);
  }, [bundle.id, addBundleToCart]);

  const handleRemoveFromCartClick = React.useCallback(() => {
    return removeFromCart(bundle.id, CART.ITEM.BUNDLES);
  }, [bundle.id, removeFromCart]);

  return (
    <Main>
      <Centerer
        withTopBarOffset
        withVerticalPadding
        sidebar={POSITION.RIGHT}
      >
        <Content>
          <Title level={2}>Bundle content</Title>
          <ArrayList<{ product: Product }>
            items={products}
            render={({ item }) => (
              <ProductRow
                {...item.product}
                discount={bundle.discount}
                showDiscount
              />
            )}
          />
          <table className={styles.total}>
            <tbody>
            <tr>
              <td>Subtotal:</td>
              <td>
                <Price
                  className={styles.price}
                  value={subtotal}
                  showCurrency
                />
              </td>
            </tr>
            <tr>
              <td>Bundle discount:</td>
              <td>{`${bundle.discount}%`}</td>
            </tr>
            <tr className="text__highlight--success">
              <td>You saved:</td>
              <td>
                <Price
                  className={styles.price}
                  value={savedAmount}
                  showCurrency
                />
              </td>
            </tr>
            <tr className="text__highlight--bold">
              <td>Total:</td>
              <td>
                <Price
                  className={styles.price}
                  value={total}
                  showCurrency
                />
              </td>
            </tr>
            </tbody>
          </table>
        </Content>
        <Sidebar sticky>
          <Title className={styles.title}>{bundle.title}</Title>
          <License/>
          {image && (
            <GatsbyImage
              className={styles.cover}
              image={image}
              alt={bundle.title}
              suppressHydrationWarning
            />
          )}
          <Excerpt>
            <HtmlContent
              tagName="p"
              value={bundle.shortDescription}
            />
            <p>{`${quantity} ${pluralize('product', quantity)} in total.`}</p>
          </Excerpt>
          <CartControls
            price={bundle.price}
            discount={bundle.discount}
            isLoading={isAuthenticating || isFetchingCart}
            isInCart={inCart}
            showDiscount
            onAddToCartClick={handleAddToCartClick}
            onRemoveClick={handleRemoveFromCartClick}
          />
        </Sidebar>
      </Centerer>
    </Main>
  );
}

export function Head({ data }: PageProps<DataType>) {
  const { bundle } = data.postgres;

  return (
    <BaseHead
      title={[bundle.title, LABEL.BUNDLES]}
      description={bundle.meta.description}
      keywords={bundle.meta.keywords}
      opengraph={bundle.opengraph}
      twitter={bundle.twitter}
    />
  );
}

export const query = graphql`
    query BundleBySlug($slug: String!) {
        postgres {
            bundle: bundleBySlug(slug: $slug) {
                id
                title
                price
                discount
                slug
                meta
                shortDescription
                cover {
                    childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED)
                    }
                }
                opengraph: openGraphImage(name: "opengraph", width: 1200, height: 630) {
                    ...OpenGraphImage
                }
                twitter: openGraphImage(name: "twitter-card", width: 1200, height: 600) {
                    ...OpenGraphImage
                }
                items: bundlesProductsList {
                    product {
                        id
                        title
                        slug
                        price
                        url
                        category {
                            slug
                            description
                        }
                        cover {
                            childImageSharp {
                                gatsbyImageData(layout: CONSTRAINED)
                            }
                        }
                        createdAt
                    }
                }
            }
        }
    }
`;
