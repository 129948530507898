import * as React from 'react';
import clsx from 'clsx';
import Price from '@components/Price';
import RemoveButton from '@components/RemoveButton';
import ViewButton from '@components/library/ViewButton';
import AddToCartButton from './AddToCartButton';
import * as styles from './CartControls.module.scss';


export type Props = {
  price: number;
  discount?: number;
  isInCart: boolean;
  isInBundle?: boolean;
  isLoading: boolean;
  showDiscount?: boolean;
  showViewButton?: boolean;
  onAddToCartClick: () => void;
  onRemoveClick: () => void;
  onViewOpen?: () => void;
};

export default function CartControls({
  price,
  discount,
  isInCart,
  isInBundle = false,
  isLoading,
  showDiscount = false,
  showViewButton = false,
  onAddToCartClick,
  onRemoveClick,
  onViewOpen,
}: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.price__wrapper}>
        {showViewButton ?
          <span className={clsx(styles.price__finalValue, styles.price__placeholder)}>In Library</span> :
          <Price
            className={styles.price__container}
            finalValueClassName={styles.price__finalValue}
            originalValueClassName={styles.price__originalValue}
            discountClassName={styles.price__discount}
            value={price}
            discount={discount}
            showDiscount={showDiscount}
            showFree
            useStyling
          />
        }
      </div>
      {showViewButton ?
        <ViewButton
          className={clsx(styles.button, styles.buttonView)}
          onClick={onViewOpen}
        /> :
        <>
          <AddToCartButton
            className={styles.button}
            isLoading={isLoading}
            isInCart={isInCart}
            isInBundle={isInBundle}
            onClick={onAddToCartClick}
          />
          {isInCart && <RemoveButton onClick={onRemoveClick}/>}
        </>
      }
    </div>
  );
}
