import * as React from 'react';
import clsx from 'clsx';
import PlusIcon from '@assets/plus.svg';
import * as styles from './RemoveButton.module.scss';


export type Props = React.HTMLAttributes<HTMLButtonElement> & {
  disabled?: boolean;
};

export default function RemoveButton({ className, ...other }: Props) {
  return (
    <button
      {...other}
      type="button"
      className={clsx(styles.button, className)}
    >
      <PlusIcon className={styles.icon}/>
    </button>
  );
}
