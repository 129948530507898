import * as React from 'react';
import * as styles from './Excerpt.module.scss';


export type Props = {
  children: React.ReactNode | React.ReactNode[];
};

export default function Excerpt({ children }: Props) {
  return <div className={styles.excerpt}>{children}</div>;
}
