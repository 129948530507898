import * as React from 'react';
import clsx from 'clsx';
import { formatPrice, calcPrice } from '@core/utils';
import * as styles from './Price.module.scss';


type Props = {
  value: number;
  discount?: number;
  showCurrency?: boolean;
  showDiscount?: boolean;
  showFree?: boolean;
  className?: string;
  finalValueClassName?: string;
  originalValueClassName?: string;
  discountClassName?: string;
  useStyling?: boolean;
}

export default function Price({
  className,
  finalValueClassName,
  originalValueClassName,
  discountClassName,
  value,
  discount = 0,
  showCurrency = false,
  showDiscount = false,
  showFree = false,
  useStyling = false,
}: Props) {
  const isDiscount = discount > 0;
  const isFree = discount === 100;
  const hasDiscount = showDiscount && isDiscount && !isFree;

  const finalPrice = React.useMemo(() => {
    if (showFree && isFree) {
      return 'FREE';
    } else if (isDiscount) {
      return formatPrice(calcPrice(value, discount));
    } else {
      return formatPrice(value);
    }
  }, [value, discount]);

  return (
    <span className={clsx(styles.container, className, { [styles.hasStyling]: useStyling })}>
      <span className={clsx(styles.finalValue, finalValueClassName, { [styles.hasDiscount]: hasDiscount || isFree })}>
        <span>{(showCurrency && !isFree ? 'USD ' : '') + finalPrice}</span>
      </span>
      {hasDiscount && (
        <span className={styles.details}>
          <small className={clsx(styles.details__originalValue, originalValueClassName)}>
            {formatPrice(value)}
          </small>
          <sup className={clsx(styles.details__discount, discountClassName)}>
            {`-${discount}%`}
          </sup>
        </span>
      )}
    </span>
  );
}
